import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import avatar from '../images/outillage.jpg'


const Watsapp = () => {
  return (
    <div>
        <FloatingWhatsApp
          phoneNumber="+32 465 48 56 04"
          accountName="Sellerie D&S"
          avatar={avatar}
          statusMessage="Répond généralement dans l'heure"
          chatMessage="Bonjour! 🤝 Comment pouvons-nous vous aidez ?"
          placeholder="Tapez votre message.."
          />
    </div>
  )
}

export default Watsapp