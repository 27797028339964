import {
    USER_LOGOUT,
    USER_LOGIN_LOADING, USER_LOGIN_SUCCESS, USER_LOGIN_FAILED,
    USER_REGISTER_FAILED, USER_REGISTER_LOADING, USER_REGISTER_SUCCESS }
from "../constants/UserConstant";

const initialState= {
    token: localStorage.getItem('token') || null,
    userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
    loading:false,
    isAuth: Boolean(localStorage.getItem('isAuth')) || false,
    errors:null,
};
const userReducer = (state=initialState,action)=>{
    switch (action.type) {
        case USER_REGISTER_LOADING:
            return {
                ...state,
                loading:true
            }
        case USER_REGISTER_SUCCESS:
            return {
                ...state,
                loading:false,
                token:action.payload.token,
                userInfo:action.payload.user,
                errors:null
            }
        case USER_REGISTER_FAILED:
            return {
                ...state,
                loading:false,
                errors:action.payload
            };
            
        case USER_LOGIN_LOADING:
            return {...state, loading:true};
        case USER_LOGIN_SUCCESS:
            localStorage.setItem('token',action.payload.token);
            localStorage.setItem('isAuth', true);
            localStorage.setItem('userInfo', JSON.stringify(action.payload.user));
            return{
                ...state,
                loading:false,
                token:action.payload.token,
                userInfo:action.payload.user,
                isAuth:true,
                errors:null
            };
        case USER_LOGIN_FAILED:
            return {
                ...state,
                loading:false,
                errors:action.payload
            };
        case USER_LOGOUT:
            localStorage.removeItem('token');
            localStorage.removeItem('userInfo');
            localStorage.removeItem('isAuth');
            return {
                ...state, 
                loading:false, 
                errors:null, 
                clientInfo:{},
                token:null,
                isAuth:false
            };

        default:
            return state
    }
}
export default userReducer;