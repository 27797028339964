export const REALIZATION_LIST_REQUEST = 'REALIZATION_LIST_REQUEST';
export const REALIZATION_LIST_SUCCESS = 'REALIZATION_LIST_SUCCESS';
export const REALIZATION_LIST_FAILED = 'REALIZATION_LIST_FAILED';


export const ADD_REALIZATION_REQUEST = 'ADD_REALIZATION_REQUEST';
export const ADD_REALIZATION_SUCCESS = 'ADD_REALIZATION_SUCCESS';
export const ADD_REALIZATION_FAILED = 'ADD_REALIZATION_FAILED';


export const UPDATE_REALIZATION_REQUEST = 'UPDATE_REALIZATION_REQUEST';
export const UPDATE_REALIZATION_SUCCESS = 'UPDATE_REALIZATION_SUCCESS';
export const UPDATE_REALIZATION_FAILED = 'UPDATE_REALIZATION_FAILED';


export const DELETE_REALIZATION_REQUEST = 'DELETE_REALIZATION_REQUEST';
export const DELETE_REALIZATION_SUCCESS = 'DELETE_REALIZATION_SUCCESS';
export const DELETE_REALIZATION_FAILED = 'DELETE_REALIZATION_FAILED';