import './Feedback.css'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addFeedback, deleteFeedback, listFeedbacks } from './../../actions/FeedbackAction';
import {Card, Modal, Button, Form} from 'react-bootstrap';
import Rating from 'react-rating-stars-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTrash, faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons'
import { useMediaQuery } from 'react-responsive';
import { useSwipeable } from 'react-swipeable';
import { Helmet } from 'react-helmet'; 

const Feedback = () => {
  const isMobile = useMediaQuery({ maxWidth: 430 });
  const isTablet = useMediaQuery({ maxWidth: 768 });
  const dispatch=useDispatch()
  const feedbacks = useSelector((state)=>state.feedbackList.feedbacks)
  const isAuth = useSelector((state)=>state.user.isAuth)
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('fr-FR', options);
};

  useEffect(()=>{
    dispatch(listFeedbacks())
  }, [dispatch])
  const [startIndex, setStartIndex]= useState(0)
  const maxFeedbacksPerPage = isMobile ? 1 : (isTablet ? 3 : 5) ;
  const handleNext = () => {
    const newIndex = startIndex + maxFeedbacksPerPage;
    if (newIndex < feedbacks.length) {
      setStartIndex(newIndex);
    }
  };
  const handlePrevious = () => {
    const newIndex = startIndex - maxFeedbacksPerPage;
    if (newIndex >= 0) {
      setStartIndex(newIndex);
    }
  };

// fonction pour défilement tactile
  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrevious 
});

  // add new feedback
  const [userName, setUserName] = useState('');
  const [content,setContent] = useState('');
  const [raiting,setRaiting] = useState('');
  const [createdate,setCreateDate] = useState('');
  const handleSubmit = async (e) =>{
    const data={
      userName,
      content,
      raiting,
      createdate
    }
    e.preventDefault()
    await dispatch(addFeedback(data))
    await dispatch(listFeedbacks())
    handleClose()
    setUserName('');
    setContent('');
    setRaiting('')

  }
  // show and hide the modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);  
  // delete feedback
  const handleDelete = async (_id)=>{
    await dispatch(deleteFeedback(_id))
    await dispatch(listFeedbacks())

  }

  return (
    <div className={`feedback ${isMobile || isTablet ? 'mobile-style' : 'desktop-style'}`}>
    
      <Helmet>
              <title>Témoignages | Sellerieds.com</title>
        <meta name="description" content="Avis et témoignages de mes clients à propos du garnissage auto, moto et bateau." />
        
        <meta property="og:title" content="Témoignages | Sellerieds.com" />
        <meta property="og:description" content="Avis et témoignages de mes clients à propos du garnissage auto, moto et bateau." />
        <meta property="og:image" content="https://www.sellerieds.com/images/temoignages.jpg" />
        <meta property="og:url" content="https://www.sellerieds.com/temoignages" />
        <meta property="og:type" content="website" />
        
        <link rel="canonical" href="https://www.sellerieds.com/temoignages" />
        
        <meta name="robots" content="index, follow" />
      </Helmet>

        <h1 className='title'>TEMOIGNAGES</h1>      
      <div className='list-feedbacks'{...handlers} >
<FontAwesomeIcon  onClick={handleNext} icon={faChevronLeft} className='button-prev-image' />
        {feedbacks.slice(startIndex, startIndex + maxFeedbacksPerPage).map((feedback)=>(
                <Card key={feedback._id} className='card'border="info">
                <Card.Header className='card-header'>
                  {feedback.userName}
                  <span> 
                  { isAuth && (
                  <FontAwesomeIcon 
                    icon={faTrash} 
                    style={{cursor:'pointer'}} 
                    onClick={()=> handleDelete(feedback._id)}/>                    
                  )}
                  </span>
                  </Card.Header>
                <Card.Body>
                  <Card.Text className='card-body-text'>{feedback.content}</Card.Text>
                </Card.Body>
                <Card.Footer className='card-footer'>
                    <Rating
                      count={5} 
                      value={feedback.raiting} 
                      size={24} 
                      edit={false} 
                      activeColor="#ffd700" 
                      inactiveColor="#e0e0e0" >
                             {feedback.raiting}
                    </Rating>
                  <Card.Text>{formatDate(feedback.createDate)}</Card.Text>              
                </Card.Footer>
              </Card>
        ))}
<FontAwesomeIcon onClick={handlePrevious} icon={faChevronRight} className='button-next-image'style={{ marginRight: '10px', cursor:'pointer' }}/>

      </div>
      <div>
        <button className='btn-add' onClick={handleShow}>Partagez votre experience</button>
        <Modal className='modal-form' show={show} onHide={handleClose}>
          <Modal.Header closeButton/>
          <Modal.Body>
            <Form encType="multipart/form-data">
              <Form.Label>Nom</Form.Label>
              <Form.Control type='text' placeholder='Tapez votre nom' value={userName} onChange={(e)=>(setUserName(e.target.value))}/>
              <Form.Label>Contenu</Form.Label>
              <Form.Control type="text" as="textarea" rows={3} placeholder='Tapez vote avis' value={content} onChange={(e)=>(setContent(e.target.value))} />
              <Form.Label>Note</Form.Label>
              <Form.Control type='text' placeholder='Tappez une note de 1 a 5' value={raiting} onChange={(e)=>(setRaiting(e.target.value))} />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button className='btn-modal' type='submit' onClick={handleSubmit}>
            Ajouter
          </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export default Feedback
