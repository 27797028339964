import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../../images/logo11.png';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAction } from '../../actions/UserAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightToBracket, faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive';

export const Navbar = () => {
    const isMobile = useMediaQuery({ maxWidth: 375 });
    const isTablet = useMediaQuery({ maxWidth:768 });
    const isDuo = useMediaQuery({ maxWidth:540 });
    const { isAuth, userInfo } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [showMenu, setShowMenu] = useState(false);
    const handleToggleMenu = () => {
        setShowMenu(!showMenu);
        console.log("showMenu:", showMenu)

    };

    return (
        <div>
                <nav className={`navbar ${ isDuo ? 'duo-style' : 
    (isMobile || isTablet ? 'mobile-style' : 'desktop-style')
  }`}>
                <div className='container'>
                    <div className='logopostion'>
                        <img src={logo} alt='sellerie DS' className='logo' />
                    </div>
                    {isMobile && (
                        <span className='nav-toggle' onClick={handleToggleMenu}>
                            ☰
                        </span>
                    )}
                    <ul className={`nav-links ${showMenu ? 'active expanded' : ''}`}>
                        <Link to='/' onClick={() => setShowMenu(false)}>
                            <li>ACCUEIL</li>
                        </Link>
                        <Link to='/about' onClick={() => setShowMenu(false)}>
                            <li>A PROPOS</li>
                        </Link>
                        <Link to='/realizations' onClick={() => setShowMenu(false)}>
                            <li>REALISATIONS</li>
                        </Link>
                        <Link to='/devis' onClick={() => setShowMenu(false)}>
                            <li>DEVIS EN LIGNE</li>
                        </Link>
                        <Link to='/feedbacks' onClick={() => setShowMenu(false)}>
                            <li>TEMOIGNAGES</li>
                        </Link>
                        <Link to='/contact' onClick={() => setShowMenu(false)}>
                    
                            <li>NOUS CONTACTER</li>
                        </Link>
                 
                    </ul>
                    {isAuth ? (
                        <>
                            <ul>
                                <h6 className='admin'>{userInfo.name}</h6>
                                <span onClick={() => dispatch(logoutAction())}>
                                    <FontAwesomeIcon className='logout' icon={faArrowRightToBracket} />
                                </span>
                                <Link to='/'></Link>
                            </ul>
                        </>
                    ) : (
                        <>
                            <ul>
                                <Link to='/login'>
                                    <FontAwesomeIcon className='login' icon={faArrowRightFromBracket}/>
                                </Link>
                            </ul>
                        </>
                    )}
                </div>             
            </nav>  
        </div>
    );
};
