import React, {useState} from 'react';
import { loginUser } from '../../actions/UserAction';
import {FloatingLabel, Spinner, Form } from 'react-bootstrap';
import { useSelector,useDispatch } from 'react-redux';
import {useNavigate} from 'react-router-dom';
import './Login.css'

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loginInput,setLoginInput]= useState({})
    const {loading,errors}= useSelector((state)=>state.user)
    const handleChange = (e)=>{
        setLoginInput({...loginInput, [e.target.name]:e.target.value})
    }
    const handleSubmit = (e)=>{
        e.preventDefault()
        dispatch(loginUser(loginInput,navigate))
    }
    return(
        <div className='login-container'>
        <div className='login-card'>
            <form onSubmit={handleSubmit}>
      <FloatingLabel controlId="floatingInput" label="Email address" className="mb-3">
        <Form.Control type="email" name='email' onChange={handleChange} />
      </FloatingLabel>
      <FloatingLabel controlId="floatingPassword" label="Password">
        <Form.Control type="password" name='password' onChange={handleChange} />
      </FloatingLabel>
      {errors && <p> {errors.msg} </p>}
      <button className='btn-submit' type='submit'>
        {loading ? (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
             ) : (
                " Connexion "
             )
        }
      </button>            
            </form>
        </div>
        </div>
    )
}

export default Login