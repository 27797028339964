import { 
    REALIZATION_LIST_REQUEST, REALIZATION_LIST_SUCCESS, REALIZATION_LIST_FAILED,
    ADD_REALIZATION_REQUEST, ADD_REALIZATION_SUCCESS, ADD_REALIZATION_FAILED,
    DELETE_REALIZATION_REQUEST, DELETE_REALIZATION_SUCCESS, DELETE_REALIZATION_FAILED,
    UPDATE_REALIZATION_REQUEST, UPDATE_REALIZATION_SUCCESS, UPDATE_REALIZATION_FAILED
} from '../constants/RealizationConstant'

const initialStateRealization= {  
    loading: false,
    realizations:[],
    errors:null,
    realizationInfo:{}
};

export const realizationReducer = (state=initialStateRealization, action)=>{
    switch(action.type){
        // get realization
        case REALIZATION_LIST_REQUEST:
            return{
                ...state,
                loading: true,
                realizations:[]
            }
        case REALIZATION_LIST_SUCCESS:
            return{
                ...state,
                loading: false,
                realizations: action.payload
            }
        case REALIZATION_LIST_FAILED:
            return{
                ...state,
                loading: false,
                error:action.payload
            }
            // add realization
            case ADD_REALIZATION_REQUEST:
                return{
                    ...state,
                    loading: true,

                }
            case ADD_REALIZATION_SUCCESS:
                return{
                    ...state,
                    loading: false,
                    realizationInfo:action.payload.createdRealization
                }
            case ADD_REALIZATION_FAILED:
                return{
                    ...state,
                    loading: false,
                    error:action.payload
                }
                // delete realization
                case DELETE_REALIZATION_REQUEST:
                    return{
                        ...state,
                        loading: true
                    }
                case DELETE_REALIZATION_SUCCESS:
                    return{
                        ...state,
                        loading: false,
                        msg:action.payload.msg
                    }
                case DELETE_REALIZATION_FAILED:
                    return{
                        ...state,
                        loading:false,
                        error:action.payload
                    }
                     // update realization
                case UPDATE_REALIZATION_REQUEST:
                return{
                    ...state,
                    loading: true
                }
            case UPDATE_REALIZATION_SUCCESS:
                return{
                    ...state,
                    loading: false,
                    msg:action.payload.msg
                    // realizationInfo:action.payload.updatedREALIZATION

                }
            case UPDATE_REALIZATION_FAILED:
                return{
                    ...state,
                    loading:false,
                    error:action.payload
                }



            default :
            return state
    }
}