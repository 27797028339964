import axios from 'axios';
import { 
    REALIZATION_LIST_REQUEST, REALIZATION_LIST_SUCCESS, REALIZATION_LIST_FAILED,
    ADD_REALIZATION_REQUEST, ADD_REALIZATION_SUCCESS, ADD_REALIZATION_FAILED,
    UPDATE_REALIZATION_REQUEST,
    UPDATE_REALIZATION_SUCCESS,
    UPDATE_REALIZATION_FAILED,
    DELETE_REALIZATION_REQUEST,
    DELETE_REALIZATION_SUCCESS,
    DELETE_REALIZATION_FAILED,

} from '../constants/RealizationConstant'

// get list realizations
export const listRealizations = () => async (dispatch) => { 
    try{
        dispatch({type:REALIZATION_LIST_REQUEST})
        const {data} = await axios.get('/api/realizations/getrealization');
        dispatch({
            type:REALIZATION_LIST_SUCCESS,
            payload: data
        })
    } catch (error){
        dispatch({
            type:REALIZATION_LIST_FAILED,
            payload:error.response && error.response.data.message 
            ? error.response.data.message
            : error.message,
        })
    }
};

// add new realization

export const addRealization = (formData) => {
    return async dispatch =>{
        dispatch({type:ADD_REALIZATION_REQUEST})
        try {
            const res = await axios.post('/api/realizations/newrealization', formData,
        {headers:{'Content-Type': 'multipart/form-data'}})
        if (res.status===200){
            dispatch({
                type:ADD_REALIZATION_SUCCESS,
                payload: res.data                        
            })
        }
        } catch (error) {
            dispatch({
                type: ADD_REALIZATION_FAILED,
                payload:error.response && error.response.data.message 
                ? error.response.data.message
                : error.message,
            })
        }
    }
}
// update realization

export const updateRealization = (_id, formData)=>{
    return async dispatch=>{
        dispatch({type:UPDATE_REALIZATION_REQUEST})
        try {
            const response = await axios.put(`/api/realizations/${_id}/updatrealization`, formData,
            {headers:{'Content-Type': 'multipart/form-data'}})
            if (response.status===200){
                dispatch({
                    type:UPDATE_REALIZATION_SUCCESS,
                    payload:response.data
                })
            }
        } catch (error) {
            dispatch({
                type:UPDATE_REALIZATION_FAILED,
                payload:error.response && error.response.data.message 
                ? error.response.data.message
                : error.message,
            })
            
        }
    }
}
// delete realization
export const deleteRealization = (_id)=>{
    return async dispatch=>{
        dispatch({type:DELETE_REALIZATION_REQUEST})
        try {
            const res = await axios.delete(`/api/realizations/${_id}/deleterealization`)
            if (res.status===200){
                dispatch({
                    type:DELETE_REALIZATION_SUCCESS,
                    payload:res.data
                })
            }
        } catch (error) {
            dispatch({
                type:DELETE_REALIZATION_FAILED,
                payload:error.response && error.response.data.message 
                ? error.response.data.message
                : error.message,
        })
    }
            }
}
