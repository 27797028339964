import './App.css';
import {Routes,Route} from "react-router-dom";
import Register from './components/authentification/Register'
import Login from './components/authentification/Login';
import 'bootstrap/dist/css/bootstrap.min.css';
import Realization from './Pages/realization/Realization';
import Feedback from './Pages/feedback/Feedback';
import Home from './Pages/home/Home';
import About from './Pages/about/About';
import Devis from './Pages/devis/Devis';
import ContactUs from './Pages/contact us/ContactUs';
import  {Navbar}  from './components/navabr/Navbar'
import Footer from './components/footer/Footer';
import Facebook from './components/Facebook'
import Watsapp from './components/Watsapp';

function App() {


  return (
    <>
    <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/about' element={<About/>}/>
        <Route path='/register' element={<Register/>} />
        <Route path='/login' element={<Login/>} />
        <Route path='/realizations' element={<Realization/>} />
        <Route path='/feedbacks' element={<Feedback/>} />
        <Route path='devis' element={<Devis/>}/>
        <Route path='/contact' element={<ContactUs/>}/>
      </Routes>
      <Facebook/>
      <Watsapp/>
    <Footer/>

    </>
  );
}

export default App;
