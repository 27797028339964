import React from 'react'
import './About.css'
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet';

const About = () => {
    const isMobile = useMediaQuery({ maxWidth: 430 });
    const isTablet = useMediaQuery({ maxWidth: 768});

  return (
    <div className={`about ${isMobile || isTablet ? 'mobile-style' : 'desktop-style'}`}>
            <Helmet>
              <title>À propos | Sellerieds.com</title>
        <meta name="description" content="Sellerie auto, garnissage tout type véhicule voitue ancienne et moderne, bateau et moto. renovation de l'intérieur, tapis, volant, ciel de toit, tableau de bord, siège auto et moto, cuir, semi-cuir" />

        <meta property="og:title" content="about | Sellerieds.com" />
        <meta property="og:description" content="Sellerie auto, garnissage tout type véhicule voitue ancienne et moderne, bateau et moto.  renovation de l'intérieur, tapis, volant, ciel de toit, tableau de bord, siège auto et moto, cuir, semi-cuir" />       
        <meta property="og:image" content="https://www.sellerieds.com/images/a propos.jpg" />
        <meta property="og:url" content="https://www.sellerieds.com/about" />
        <meta property="og:type" content="website" />

        <link rel="canonical" href="https://www.sellerieds.com/about" />

        <meta name="robots" content="index, follow" />
      </Helmet>
        <h1 className='title'>À PROPOS</h1>
<div className='in-about'>
        
        <h4>À Propos de Nous</h4>
        <p> Sellerie D&S est bien plus q'une sellerie. 
            Notre équipe de professionnels passionnés transforme vos espaces automobiles et 
            nautiques  en chefs-d'oeuvre de confort et de style, 
            avec des années d'expertise dans le domaine</p>
        <h4>Service</h4>
        <h6 style={{textDecoration:'underline'}}> Garnissage automobile:</h6>
        <p> Rénovation et personnalisation complète de l'intérieur de votre voiture pour un 
            confort optimal.
            Nous nous spécialisons dans la restauration des voitures ancêtre et moderne, offrant des solutions sur mesure telles que des tapis sur mesure 
            la rénovation du cuir, la restauration des volants et des ciels de toit, ainsi que des 
            sièges en cuir et des tableaux de bord.
        </p>
        <h6 style={{textDecoration:'underline'}}>Garnissage auto et bateau:</h6>
        <p>Services spécialisés pour les véhicules terrestres et les embaracations, répondant 
            à vos besoins uniques. Nous proposons une gamme complète de services, y compris la restauration 
            des sièges de moto, la rénovation des sièges de bateau en cuir et en tissu, ainsi que des options 
            de cuir semi-cuir sur mesure.
        </p>    
        <h4>Pourquoi Choisir Sellerie D&S</h4>
        <h6 style={{textDecoration:'underline'}}>Expertise:</h6>
        <p>Notre équipe qualifié garantit des résultats exceptionnels.</p>
        <h6 style={{textDecoration:'underline'}}>Personnalisation:</h6>
        <p>Chaque projet est traité avec une approche personnalisée.</p>
        <h6 style={{textDecoration:'underline'}}>Qualité:</h6>
        <p>Utilisation de matériaux haut de gamme pour un garnissage durable et esthétique.</p>
        <h4>Cantactez-Nous</h4>
        <p>Confiez votre projet de sellerie sur mesure à sellerie D&S dès ajourd'hui. Nous somme là 
            pour vous accompagner vers une éxpérience de confort et de style inoubliable.
        </p>            
</div>
    </div>
  )
}

export default About
