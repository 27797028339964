import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import firstSlide from '.././../images/auto2.jpg'
import secondSlide from '.././../images/nautique.jpg'
import thirdSlice from '.././../images/moto2.jpg'
import './Home.css'
import About from '../about/About';
import Realization from '../realization/Realization'
import Devis from './../devis/Devis';
import Feedback from '../feedback/Feedback'
import ContactUs from '../contact us/ContactUs';
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet';

const Home = () => {
  const isMobile = useMediaQuery({ maxWidth: 430 });
  const isTablet = useMediaQuery({ maxWidth: 768 });

  return (
    <div className={`home-page ${isMobile || isTablet ? 'mobile-style' : 'desktop-style'}`}>
<Helmet>
        <title>Acceuil | Sellerieds.com</title>
        <meta name="description" content="sellerie, garnissage, restauration auto, moto et bateau." />
        
        <meta property="og:title" content="Acceuil | Sellerieds.com" />
        <meta property="og:description" content="sellerie, garnissage, restauration auto, moto et bateau." />
        <meta property="og:image" content="https://www.sellerieds.com/images/home.jpg" />
        <meta property="og:url" content="https://www.sellerieds.com/home" />
        <meta property="og:type" content="website" />
        
        <link rel="canonical" href="https://www.sellerieds.com/home" />
        
        <meta name="robots" content="index, follow" />
      </Helmet>
    <div className='home'>
    <Carousel>
      <Carousel.Item className='container-item'>
        <img className='container-item-image' src={firstSlide} alt='modele1'/>
      </Carousel.Item>
      <Carousel.Item>
        <img className='container-item-image' src={secondSlide} alt="Second slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className='container-item-image' src={thirdSlice} alt="Third slide" />
      </Carousel.Item>
    </Carousel>
    </div>
<div>
      <About/>
      <Realization/>
      <Devis/>
      <Feedback/>
      <ContactUs/>
</div>

    </div>
    
  )
}

export default Home
