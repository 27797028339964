import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faLocationDot} from '@fortawesome/free-solid-svg-icons';
import './Footer.css'
import { FaFacebook } from "react-icons/fa";

const Footer = () => {
  return (
    <div className='footer'>
      <div className='part1'>
        <h3>À Propos</h3>
        <p>Prestataire spécialisé dans le garnissage de tous types de véhicules, voiture ancêtre et moderne, moto et bateau.</p>
      </div>
      <div className='part2'>
        <h3>Liens Utiles</h3>
        <ul>
          <li><a href="#">Accueil</a></li>
          <li><a href="about">À Propos</a></li>
          <li><a href="realizations">Réalisations</a></li>
          <li><a href="devis">Devis</a></li>
        </ul>
      </div>
      <div className='part3'>
        <h3>Nous Contacter</h3>
        <FontAwesomeIcon icon={faPhone}/> 
          <span>(+32) 465 48 56 04</span> <br/>
        <FontAwesomeIcon icon={faEnvelope} />
          <span>info.sellerieds@gmail.com</span> <br/>
        <FontAwesomeIcon icon={faLocationDot} /> 
          <span>Rue du Fort Mahon 46, 7012 Mons, Belgique</span> <br/>
        <FaFacebook />
          <span>Sellerie D&S</span>
      </div>
    </div>

  )
}

export default Footer