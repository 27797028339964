import React from 'react'
import fb from '../images/fb6.png'
const Facebook = () => {
  return (
    <div style={{position:'fixed', bottom:'90px', right:'20px', zIndex:'1000', backgroundColor:'transparent'}}>
        <a 
         href="https://www.facebook.com/SellerieGarnissage" 
         target="_blank">
            <img style={{width:'88px', height:'88px'}} src={fb} alt='facebook-icon' />
        </a> 
    </div> 
  )
}

export default Facebook