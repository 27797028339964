import {combineReducers,createStore,applyMiddleware,compose} from 'redux';
import userReducer from './reducers/UserReducer';
import {realizationReducer} from './reducers/RealizationReducer'
import { thunk } from 'redux-thunk';
import { feedbackListReducer } from './reducers/FeedbackReducer';

const rootReducer = combineReducers({
    user:userReducer,
    realizationList: realizationReducer,
    feedbackList: feedbackListReducer
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
);

export default store;