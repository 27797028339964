import axios from "axios";
import { 
     USER_LOGIN_FAILED, USER_LOGIN_LOADING, USER_LOGIN_SUCCESS,
     USER_LOGOUT,
     USER_REGISTER_FAILED, USER_REGISTER_LOADING, USER_REGISTER_SUCCESS } from "../constants/UserConstant";

export const registerNewUser =(userInfo,navigate)=> async(dispatch)=>{
    try {
        dispatch({type:USER_REGISTER_LOADING})
        const res = await axios.post('/api/users/register',userInfo)
        dispatch({type:USER_REGISTER_SUCCESS,payload:res.data})
        navigate('/login')
    } catch (error) {
        dispatch({type:USER_REGISTER_FAILED,payload:error.response.data.errors}) 
    }
};

export const loginUser = (userInfo,navigate) => async (dispatch) =>{
    try {
        dispatch({type:USER_LOGIN_LOADING});
        const res = await axios.post('/api/users/login', userInfo);
        dispatch({type:USER_LOGIN_SUCCESS, payload: res.data});
        navigate('/')

    } catch (error) {
        dispatch({type:USER_LOGIN_FAILED, payload:error})
    }
};

export const logoutAction = ()=>{
    return {
        type: USER_LOGOUT
    }
};