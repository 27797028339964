export const FEEDBACK_LIST_REQUEST = 'FEEDBACK_LIST_REQUEST';
export const FEEDBACK_LIST_SUCCESS = 'FEEDBACK_LIST_SUCCESS';
export const FEEDBACK_LIST_FAILED = 'FEEDBACK_LIST_FAILED';


export const ADD_FEEDBACK_REQUEST = 'ADD_FEEDBACK_REQUEST';
export const ADD_FEEDBACK_SUCCESS = 'ADD_FEEDBACK_SUCCESS';
export const ADD_FEEDBACK_FAILED = 'ADD_FEEDBACK_FAILED';



export const DELETE_FEEDBACK_REQUEST = 'DELETE_FEEDBACK_REQUEST';
export const DELETE_FEEDBACK_SUCCESS = 'DELETE_FEEDBACK_SUCCESS';
export const DELETE_FEEDBACK_FAILED = 'DELETE_FEEDBACK_FAILED';