import axios from 'axios';
import {
    ADD_FEEDBACK_FAILED, ADD_FEEDBACK_REQUEST, ADD_FEEDBACK_SUCCESS,
    DELETE_FEEDBACK_FAILED, DELETE_FEEDBACK_REQUEST, DELETE_FEEDBACK_SUCCESS,
    FEEDBACK_LIST_FAILED, FEEDBACK_LIST_REQUEST, FEEDBACK_LIST_SUCCESS 
} from '../constants/FeedbackConstant';



 export const listFeedbacks = () => async (dispatch)=>{
    try{
        dispatch({type:FEEDBACK_LIST_REQUEST})
        const {data} = await axios.get('/api/feedbacks/getfeed')
        dispatch({
            type:FEEDBACK_LIST_SUCCESS,
            payload: data
        })
    } catch (error){
        dispatch({
            type: FEEDBACK_LIST_FAILED,
            payload:error.response && error.response.data.message 
            ? error.response.data.message
            : error.message,
        })
    }
};

// add new feedback
export const addFeedback = (data) => {
    return async dispatch=>{
        dispatch({type:ADD_FEEDBACK_REQUEST})
    try{
        const res = await axios.post('/api/feedbacks/addfeed', data,
        {headers:{token:localStorage.getItem('token')}})
        if (res.status===200){
            dispatch({
                type:ADD_FEEDBACK_SUCCESS,
                payload:res.data
            })
        }
    } catch (error){
        dispatch({
            type: ADD_FEEDBACK_FAILED,
            payload:error.response && error.response.data.message 
            ? error.response.data.message
            : error.message,
        })
    }} 
}


// deleteproduct
export const deleteFeedback = (_id)=>{

    return async dispatch =>{
        dispatch({type:DELETE_FEEDBACK_REQUEST})
    try {
        const res = await axios.delete(`/api/feedbacks/${_id}/delfeed`,
    {headers:{token:localStorage.getItem('token')}})
    if (res.status===200){
        dispatch({
            type:DELETE_FEEDBACK_SUCCESS,
            payload:res.data
        })
    }
            
  } catch (error) {
    dispatch({
        type: DELETE_FEEDBACK_FAILED,
        payload:error.response && error.response.data.message 
        ? error.response.data.message
        : error.message,
    })
            
        }

    }
}