import './Realization.css'
import React,{useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { listRealizations, addRealization, updateRealization, deleteRealization } from '../../actions/RealizationAction'
import {Modal, Button, Form} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChevronLeft, faPenToSquare, faTrash, faChevronRight} from '@fortawesome/free-solid-svg-icons'
import { useMediaQuery } from 'react-responsive';
import { useSwipeable } from 'react-swipeable';
import { Helmet } from 'react-helmet';

const Realization = () => {
      const isMobile = useMediaQuery({ maxWidth: 430 });
      const isTablet = useMediaQuery({ maxWidth: 768})

    const dispatch=useDispatch()
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
// get list realizations
const realizations = useSelector((state)=>state.realizationList.realizations)
const isAuth = useSelector((state)=>state.user.isAuth)
const [selectedImage, setSelectedImage] = useState(null);
const [currentImage, setCurrentImage] = useState(0);
const [thumbnails, setThumbnails] = useState([]);

useEffect(()=>{
    dispatch(listRealizations())
}, [dispatch])
useEffect(() => {
    if (realizations.length > 0) {
        setSelectedImage(realizations[0].image); 
        setCurrentImage(0);
    }
}, [realizations]);


const handleThumbnailClick = ( index) => {
    setSelectedImage(realizations[index].image);
    setCurrentImage(index);
};


const handleNextImage = () => {
    const nextIndex = (currentImage + 1) % realizations.length;
    setSelectedImage(realizations[nextIndex].image);
    setCurrentImage(nextIndex);
};

const handlePrevImage = () => {
    const prevIndex = (currentImage - 1 + realizations.length) % realizations.length;
    setSelectedImage(realizations[prevIndex].image);
    setCurrentImage(prevIndex);
};
const handlers = useSwipeable({
    onSwipedLeft: handleNextImage,
    onSwipedRight: handlePrevImage
});

// add new realization
const [file, setFile] = useState(null)
const [description, setDescription] = useState('')
const handleFileChange = (e)=> {
    setFile(e.target.files[0])
}
const handleDescriptionChange = (e) =>{
    setDescription(e.target.value)
}
const handleSubmit = async (e)=> {
    e.preventDefault()
    const formData = new FormData();
    formData.append('picture', file);
    formData.append('info', JSON.stringify({ description }));
    await dispatch(addRealization(formData));
    await dispatch(listRealizations())
    handleClose()
}

// edit realization
const [edit,setEdit] = useState(false);
const [id,setId] = useState('');
const handleCloseEdit = () => setEdit(false);
const handleShowEdit = (_id) => {
    realizations.forEach(realization=>{
        if (realization._id===_id){
            setId(realization._id)
            setFile(realization.file)
            setDescription(realization.description)
        }
    })
    console.log(_id)
    setEdit(true);
}
const handleEdit = async (e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append('picture', file);
    formData.append('info', JSON.stringify({ description }));
    await dispatch(updateRealization(id,formData));
    await dispatch(listRealizations())
    handleCloseEdit()
    setId('')
    setFile('')
    setDescription('')
}
// delete realization
const handleDelete = async(_id)=>{
    await dispatch(deleteRealization(_id))
    await dispatch(listRealizations())
}
   // Fonction pour rendre les miniatures
   const renderThumbnails = () => {
    const thumbnails = [];
    // Calculer les bornes de début et de fin pour les miniatures à afficher
    const start = Math.max(0, currentImage - 2);
    const end = Math.min(realizations.length, start + 5);

    // Ajouter les miniatures à afficher
    for (let i = start; i < end; i++) {
        thumbnails.push(
            <div key={realizations[i]._id}>
                {isAuth && (
                    <div className='private-fonction'>
                        <FontAwesomeIcon
                            icon={faPenToSquare}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleShowEdit(realizations[i]._id)}
                        />
                        <FontAwesomeIcon
                            icon={faTrash}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleDelete(realizations[i]._id)}
                        />
                    </div>
                )}
                <div>
                    <img
                        className='min-image'
                        src={realizations[i].image}
                        alt={`Thumbnail ${i}`}
                        onClick={() => handleThumbnailClick(i)}
                    />
                </div>
            </div>
        );
    }

    return thumbnails;
};
  return (
    <div className={`realization ${isMobile || isTablet ? 'mobile-style' : 'desktop-style'}`} >
      <Helmet>
              <title>Réalisations | Sellerieds.com</title>
        <meta name="description" content="Mes realisations telles que le garnissage de tout type de véhicules, voiture ancêtre et moderne, moto et bateau." />

        <meta property="og:title" content="Réalisations | Sellerieds.com" />
        <meta property="og:description" content="Mes realisations telles que le garnissage de tout type de véhicules, voiture ancêtre et moderne, moto et bateau." />
        <meta property="og:image" content="https://www.sellerieds.com/images/realisations.jpg" />
        <meta property="og:url" content="https://www.sellerieds.com/realisations" />
        <meta property="og:type" content="website" />

        <link rel="canonical" href="https://www.sellerieds.com/realisations" />

        <meta name="robots" content="index, follow" />
      </Helmet>
        {/* get all realization */}
            <h2 className='title'>MES REALISATIONS</h2>
        <div className='division'{...handlers}>
        <div className="image-container">
        <FontAwesomeIcon 
                    onClick={handlePrevImage} 
                    icon={faChevronLeft} 
                    className="button-prev-selected-image" 
                />
        {selectedImage &&( 
        <img className='selected-image' src={selectedImage} alt="Selected Image"  />
        )}
        <FontAwesomeIcon 
                    onClick={handleNextImage} 
                    icon={faChevronRight} 
                    className="button-next-selected-image" 
                />
        </div>
<div className='in-division'>
<FontAwesomeIcon  onClick={handlePrevImage} icon={faChevronLeft} className='button-prev-image' />
    <div className='list-min-picture' >
        {renderThumbnails()}
    </div>
    <FontAwesomeIcon onClick={handleNextImage} icon={faChevronRight} className='button-next-image'style={{ marginRight: '10px', cursor:'pointer' }}/>
         </div>
        </div>
        {/* add realization */}
        { isAuth && (
<button className='btn-add' onClick={handleShow}>ajoutez une nouvelle realisation</button>
        )}
<Modal className='modal-form' show={show} onHide={handleClose}>
<Modal.Header closeButton />
        <Modal.Body>
            <Form  encType="multipart/form-data">
                <div>
                    <Form.Label htmlFor="file">Image:</Form.Label>
                    <Form.Control type="file" id="file" onChange={handleFileChange} />
                </div>
                <div>
                    <Form.Label htmlFor="description">Description:</Form.Label>
                    <Form.Control type="text" id="description" value={description} onChange={handleDescriptionChange} />
                </div>
            <Modal.Footer>
          <Button className='btn-form' type='submit' onClick={handleSubmit}>
            Ajouter
          </Button>
        </Modal.Footer>                
            </Form>
            </Modal.Body>
            </Modal>
        <div>

        </div>
        {/* update realization */}
        <div>
            <Modal show={edit} onHide={handleCloseEdit}>
                <Modal.Header closeButton/>
                <Modal.Body>
                    <form>
                        <label htmlFor="file">image</label>
                        <input  type="file" id="file" onChange={(e)=>setFile(e.target.files[0])}/>
                        <label htmlFor="description">description</label>
                        <input type="text" id="description" value={description} onChange={(e)=>setDescription(e.target.value)}/>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" onClick={handleEdit} >save</Button>
                </Modal.Footer>

            </Modal>

        </div>

    </div>
  )
}

export default Realization
