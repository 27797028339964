import { 
    ADD_FEEDBACK_FAILED, ADD_FEEDBACK_REQUEST, ADD_FEEDBACK_SUCCESS,
    DELETE_FEEDBACK_FAILED, DELETE_FEEDBACK_REQUEST, DELETE_FEEDBACK_SUCCESS,
    FEEDBACK_LIST_FAILED, FEEDBACK_LIST_REQUEST, FEEDBACK_LIST_SUCCESS 
    } from "../constants/FeedbackConstant";




const initialStateFeedback= {
    loading:false,
    feedbacks:[],
    errors:null,
    createdF:{}
};


export const feedbackListReducer = (state=initialStateFeedback, action)=>{
    switch(action.type){
        // get feedback
        case FEEDBACK_LIST_REQUEST:
            return{
                ...state,
                loading:true,
                feedbacks:[]
            }
        case FEEDBACK_LIST_SUCCESS:
            return{
                ...state,
                loading:false,
                feedbacks: action.payload
            }
        case FEEDBACK_LIST_FAILED:
            return{
                ...state,
                loading:false,
                error:action.payload
            }
            // add feedback
            case ADD_FEEDBACK_REQUEST:
                return{
                    ...state,
                    loading:true,
                }
            case ADD_FEEDBACK_SUCCESS:
                return{
                    ...state,
                    loading:false,
                    createdF:action.payload.createdFeedback
                }
            case ADD_FEEDBACK_FAILED:
                return{
                    ...state,
                    loading:false,
                    error:action.payload
                }

                // delete feedback
                case DELETE_FEEDBACK_REQUEST:
                    return{
                        ...state,
                        loading:true,
                    }
                case DELETE_FEEDBACK_SUCCESS:
                    return{
                        ...state,
                        loading:false,
                        msg:action.payload.msg
                    }
                case DELETE_FEEDBACK_FAILED:
                    return{
                        ...state,
                        loading:false,
                        error:action.payload
                    }



            default :
            return state
    }
}