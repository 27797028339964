import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPhone, faEnvelope, faLocationDot} from '@fortawesome/free-solid-svg-icons'
import './ContactUs.css'
import { useMediaQuery } from 'react-responsive';


const ContactUs = () => {
  const isMobile = useMediaQuery({ maxWidth: 430 });
  const isTablet = useMediaQuery({ maxWidth:768})

  return (
    <div className={`contactus ${isMobile || isTablet ? 'mobile-style' : 'desktop-style'}`}>
        <h1 className='title'>NOUS CONTACTER</h1>
        <div className='containers'>
            <div className='container-1'>
                <div>
                    <FontAwesomeIcon icon={faPhone} />
                    <span> <a href="tel:+32465485604">(+32) 465 48 56 04</a> </span>
                </div>
                <div>
                   <FontAwesomeIcon icon={faEnvelope} />
                   <span> info.sellerieds@gmail.com</span>
                </div>
                <div>
                   <FontAwesomeIcon icon={faLocationDot} /> 
                   <span> Rue du Fort Mahon 46, 7012 Mons, Belgique</span>
                </div>
                <div>
                   <h6 style={{textDecoration:'underline'}}>Horaires:</h6>
                   <span> Lundi - samedi de 9:00 à 18:00</span>
                </div>         
            </div>
            <div className='container-2'>
                   <iframe className='iframe'
                   src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2540.6932889221193!2d3.8903809757817065!3d50.446813387626655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c25b67a49f18cb%3A0x59ed90cfd479a2a3!2sSellerie%20D%26S!5e0!3m2!1sfr!2stn!4v1714907688439!5m2!1sfr!2stn"
                   
                   loading="lazy" 
                   referrerpolicy="no-referrer-when-downgrade">
                   </iframe>
            </div>
        </div>
    </div>
  )
}

export default ContactUs
