import React, {useRef, useState} from 'react'
import {Button,Form, Modal} from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import './Devis.css'
import { useMediaQuery } from 'react-responsive';



const Devis = () => {
  const isMobile = useMediaQuery({ maxWidth: 430 });
  const isTablet = useMediaQuery({ maxWidth: 768 })

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_294m0zn', 'template_scrvfqm', form.current, 'FEd7sel1TZL-piwTf')
          .then((response) => {
              console.log(response.text);
          })
          .catch((error) => {
              console.log(error.text);
          });
          
          handleClose()
      };
  return (
    <div className={`devis ${isMobile || isTablet ? 'mobile-style' : 'desktop-style'}`}>
        <h1 className='title'>DEVIS</h1>
        <Button className='btn-add' onClick={handleShow}>
        Obtenez votre devis personalisé !!!
      </Button>
      <Modal className='modal-form' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <Form ref={form}  className="form">
      <Form.Group className="mb-3" >
        <br/>
        <Form.Label>Nom</Form.Label>
        <Form.Control type="text" name="name" placeholder="Saisir votre nom" />
      </Form.Group>
      <Form.Group className="mb-3" >
        <Form.Label>Télèphone</Form.Label>
        <Form.Control type="text" name="telephone" placeholder="Saisir votre numéro de télèphone" />
      </Form.Group>
      <Form.Group className="mb-3" >
        <Form.Label>Email</Form.Label>
        <Form.Control type="email" name="email" placeholder="Saisir votre email" />
      </Form.Group>
      <Form.Group className="mb-3" >
        <Form.Label>Demande de devis</Form.Label>
        <Form.Control type="text" as="textarea" rows={3} name="message" placeholder="Saisir votre demande de devis" />
      </Form.Group>
    </Form> 
        </Modal.Body>
        <Modal.Footer>
          <Button type='submit' className='btn-modal' onClick={sendEmail}>
            Envoyer
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}

export default Devis
