import React, { useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { registerNewUser } from '../../actions/UserAction'
import { Spinner, Button } from 'react-bootstrap';

const Register = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {loading, errors} = useSelector((state)=> state.user)   
    const [registerInput, setRegisterInput]= useState({})
    const handleChange=(e)=>{
        setRegisterInput({...registerInput, [e.target.name]:e.target.value})
    }
    const handleSubmitRegister = (e)=>{
        e.preventDefault();
        dispatch(registerNewUser(registerInput,navigate))
    }
    return(
        <div className='register'>
            <form onSubmit={handleSubmitRegister}>
             <div>
              <label>Name</label>
              <input type="text" name='name' onChange={handleChange} />                     
             </div>
             <div>
               <label>email</label>
               <input type="email" name='email' onChange={handleChange} /> 
             </div>
             <div>
               <label>password</label>
               <input type="password" name='password' onChange={handleChange} />
             </div>
             <div>
               <button type='submit' > Enregistrer </button> 
             </div>
            </form>
            {errors && errors.map((el) => <p>{el.msg}</p>)}
            <Button  type="submit" onClick={handleSubmitRegister}>
        {loading ? (<Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
        </Spinner>) :(<strong>submit</strong>)
        }
      </Button>
        </div>
    )

}
export default Register